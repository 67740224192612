import React, { Component } from "react";
import Items from "./CartItems";
import '../style/order.scss';
import { 
    Container
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import { metaTitle, take_away, home_delivery, table_order, currency } from '../config';

class Cart extends Component {

  state = {
    null_item: false,
    cart_item: [],
    table: 'home',
    items: [],
    total_items: 0,
    total_amount: 0,
    position: '',
    address: '',
    address_text: 'Address and Landmark:'
  }

  componentDidMount(){
    let table = sessionStorage.getItem("table");
    if(!table){
      table = 'home';
    }
    const cart = sessionStorage.getItem('cart');
    let cart_item = [];
    if (cart) {
      cart_item = JSON.parse(cart);
    }else{
      this.setState({ null_item: true });
    }

    let address_text = '';
    if(table === 'home'){
      address_text = 'Address and Landmark:';
    }else if(table === 'take'){
      address_text = 'More Info:';
    }else if(table !== ''){
      address_text = 'Table Order';
    }else{
      address_text = 'Address and Landmark:';
    }

    this.setState({
      cart_item: cart_item,
      table: table,
      address_text: address_text
    });
    this.setGeo();
  }

  setGeo(){
    navigator.geolocation.getCurrentPosition(function(position) {
        sessionStorage.setItem('position', position.coords.latitude+','+position.coords.longitude);
    });
  }

  addItems = (items) => {
    this.setState({
      items: items
    });
  }

  increment = (id) =>{
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        let i_count = item.count+1;
        cart[key] = {
          id: id,
          count: i_count,
          note: item.note
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  decrement = (id) =>{
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        let i_count = item.count-1;
        if(i_count <= 0){
          i_count = 0;
        }
        cart[key] = {
          id: id,
          count: i_count,
          note: item.note
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  addNote = (event) =>{
    let id = event.target.name;
    let value  = event.target.value;
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === id){
        cart[key] = {
          id: id,
          count: item.count,
          note: value
        }
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  round = (value) => {
    let num = parseFloat(value).toFixed(2);
    return num;
  }

  whatsApp = () => {
    let position = sessionStorage.getItem("position");
    let table = '';
    let whatsapp_number = '';
    if(this.state.table === 'home'){
      table = 'Home Delivery';
      whatsapp_number = home_delivery;
    }else if(this.state.table === 'take'){
      table = 'Take away';
      whatsapp_number = take_away;
    }else if(this.state.table !== ''){
      table = 'Table No: '+this.state.table;
      whatsapp_number = table_order;
    }else{
      table = 'Home Delivery';
      whatsapp_number = home_delivery;
    }
    let product = this.state.items;
    let cart = this.state.cart_item;
    let i_count = 0;
    let i_note = '';
    let i_total = 0;
    let s_total = 0;
    let t_items = 0;

    let data = '*'+table+'* \n\n';
    cart.forEach((cart_item) => {
      i_count = cart_item.count;
      i_note = cart_item.note;
      if(i_count !== 0){
        product.forEach((product_item) => {
          if(cart_item.id === product_item.id){
            t_items += i_count;
            i_total = product_item.price*i_count;
            s_total += i_total;
            data += '*'+product_item.title+' ('+product_item.category+')* \n';
            data += this.round(product_item.price)+' '+currency+' x'+i_count+' ='+this.round(i_total)+' '+currency+' \n';
            data += '_'+i_note+'_ \n';
            data += '\n';
          }
        });
      }
    });

    data += '\n';
    data += '*Total Amount: '+this.round(s_total)+' '+currency+'*';

    if(this.state.name)
      data += '\n\n*Name:* '+this.state.name;

    if(this.state.vehcle)
    data += '\n*Vehcle Number:* '+this.state.vehcle;

    if(this.state.address)
      data += '\n*'+this.state.address_text+'* \n'+this.state.address;
    
    data += '\n\nhttps://maps.google.com/maps?daddr='+position+'';

    this.setState({
      total_items: t_items,
      total_amount: s_total,
      position: position
    });

    window.open('https://wa.me/'+whatsapp_number+'?text='+encodeURI(data));
  }

  deleteItem = (items) => {
    let cart = this.state.cart_item;
    cart.forEach((item, key) => {
      if(item.id === items){
        cart.splice(key, 1);
        return true;
      }
    });
    sessionStorage.setItem('cart', JSON.stringify(cart));
    this.setState({
      cart_item: cart
    });
  }

  clear = () => {
    sessionStorage.removeItem('cart');
    this.setState({
      null_item: true
     });
  }

  tableChange = (table) => {
    let address_text = '';
    if(this.state.table === 'home'){
      address_text = 'Address and Landmark:';
    }else if(this.state.table === 'take'){
      address_text = 'More Info:';
    }else if(this.state.table !== ''){
      address_text = 'Table Order';
    }else{
      address_text = 'Address and Landmark:';
    }

    this.setState({
      table: table,
      address_text: address_text
    });
  }

  inputChange = (event) =>{
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    if(this.state.null_item === true){
      return <Redirect to='/order' />
    }
    
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ metaTitle('Cart') }</title>
        </Helmet>
        <Container>
          <Items clear={this.clear} increment={this.increment} decrement={this.decrement} deleteItem={this.deleteItem} addNote={this.addNote} cart_item={this.state.cart_item} addItems={this.addItems} round={this.round}/>
        </Container>

        {(this.state.table === 'home' || this.state.table === 'take') ? (
        <Container>
          <Row>
              <Col md={12}>
              <div className="sale-items order-clear-box">
                <div className="cart-clear-div address-tab">
                  <label>Name:</label>
                  <input type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.inputChange}/>
                </div>
              </div>
            </Col>
            <Col md={12}  className={(this.state.table !== 'take') ? 'hide' : ''}>
              <div className="sale-items order-clear-box">
                <div className="cart-clear-div address-tab">
                  <label>Vehcle Number:</label>
                  <input type="text" name="vehcle" placeholder="Vehcle Number" value={this.state.vehcle} onChange={this.inputChange}/>
                </div>
              </div>
            </Col>
            <Col md={12} className={(this.state.table !== 'home') ? 'hide' : ''}>
              <div className="sale-items order-clear-box">
                <div className="cart-clear-div address-tab">
                  <label>{this.state.address_text}</label>
                  <textarea name="address" placeholder={this.state.address_text} value={this.state.address} onChange={this.inputChange}></textarea>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        ): '' }
        <Container className="con-pol-order">
        {(this.state.table === 'home' || this.state.table === 'take') ? (
          <button className={(this.state.table === 'home') ? 'pol-order active' : 'pol-order'} onClick={() => this.tableChange('home')}>Home Delivery</button>
          ): '' }
          {(this.state.table === 'home' || this.state.table === 'take') ? (
          <span>OR</span>
          ): '' }
          {(this.state.table === 'home' || this.state.table === 'take') ? (
          <button className={(this.state.table === 'take') ? 'pol-order active' : 'pol-order'} onClick={() => this.tableChange('take')}>Take Away</button>
          ): '' }
          {(this.state.table !== 'home' && this.state.table !== 'take' && this.state.table !== '') ? <button className="pol-order pol-order-bot active">Table No: {this.state.table}</button> : '' }
        </Container>

        <Container>
          <div className="whatsapp-share">
            <button className="whatsapp-share-link" onClick={ this.whatsApp }>
              <i className="logo-whatsapp"></i>
              <label>Order on whatsapp</label>
            </button>
          </div>
        </Container>
        
      </React.Fragment>
    );
  }
}
export default Cart;

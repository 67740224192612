export const app_title = 'Restaurant';
export const app_for = 'Restaurant';
export const api_base = 'https://admin.qa.ezyerp.net/api/v1';
export const currency = 'QR';

export const mobile = '97450416493';
export const take_away = '97450416493';
export const home_delivery = '97450416493';
export const table_order = '97450416493';
export const metaTitle = (title = '') => {
    if(title !== ''){
        title = ' | '+title;
    }
    return "Booking App "+title;
}